/**
 * Stores build information. The string literals are replaced at compile time by `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-08-13",
    versionChannel: "nightly",
    buildDate: "2024-08-13T00:05:24.859Z",
    commitHash: "79817f41d85451e1ec1b59c34a46c492cd802155",
};
